<template>
  <div v-if="!isMobile">
    <VContainer
      v-show="!loading"
      class="pa-0"
    >
      <VRow>
        <VCol>
          <h1
            data-test="levels-view-h1"
            class="tt-text-headline-1"
          >
            {{ $t('menu.trainees') }}
          </h1>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="10">
          <VList>
            <template v-for="(trainee,index) in traineesList">
              <VListItem
                :key="`trainee-item-${trainee.id}`"
                :to="{name : Names.R_APP_TRAINEE_PROFILE,params : {userId : trainee.id}}"
              >
                <VListItemAvatar>
                  <TTAvatar
                    :src="trainee.photoUrl"
                    :text="getUserInitials(trainee.firstName, trainee.lastName,trainee.photoUrl)"
                  />
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle class="tt-text-body-1">
                    {{ trainee.firstName }} {{ trainee.lastName }}
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <div class="tt-text-body-1 tt-light-mono-64--text">
                    {{ trainee.stat.checkpointsCompleted }}/{{ trainee.stat.checkpointsTotal }}
                  </div>
                </VListItemAction>
              </VListItem>
              <VDivider
                :key="index"
                class="my-2"
              />
            </template>
          </VList>
        </VCol>
      </VRow>
    </VContainer>
  </div>
  <div v-else>
    <AppBarMobile>
      <VRow
        align="center"
        justify="center"
      >
        <VCol cols="auto">
          <h1
            data-test="trainees-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $t('menu.trainees') }}
          </h1>
        </VCol>
      </VRow>
    </AppBarMobile>
    <VContainer
      v-show="!loading"
    >
      <VRow>
        <VCol cols="12">
          <VList>
            <template v-for="(trainee,index) in traineesList">
              <VListItem
                :key="`trainee-item-${trainee.id}`"
                :to="{name : Names.R_APP_TRAINEE_PROFILE,params : {userId : trainee.id}}"
              >
                <VListItemAvatar>
                  <TTAvatar
                    :src="trainee.photoUrl"
                    :text="getUserInitials(trainee.firstName, trainee.lastName,trainee.photoUrl)"
                  />
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle class="tt-text-body-1">
                    {{ trainee.firstName }} {{ trainee.lastName }}
                  </VListItemTitle>
                </VListItemContent>
                <VListItemAction>
                  <div class="tt-text-body-1 tt-light-mono-64--text">
                    {{ trainee.stat.checkpointsCompleted }}/{{ trainee.stat.checkpointsTotal }}
                  </div>
                </VListItemAction>
              </VListItem>
              <VDivider
                :key="index"
                class="my-2"
              />
            </template>
          </VList>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import * as snamiApi from '@/services/api/snami';
import generateUserAvatar from '@/helpers/user';
import AppBarMobile from '@/components/app/AppBarMobile.vue';

export default {
  name: 'Index',
  components: { AppBarMobile },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      traineesList: [],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    this.getTutorStaffList();
  },
  methods: {
    getUserInitials: generateUserAvatar,
    async getTutorStaffList() {
      try {
        this.loading = true;
        const { data } = await snamiApi.tutorStaffListGet();
        this.traineesList = data;
      } catch (e) {
        // TODO на обработчик ошибки
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
