/**
 *
 * @param firstName
 * @param lastName
 * @param photoUrl
 * @returns {string|*}
 */
export default function generateUserAvatar(firstName, lastName, photoUrl) {
  if (photoUrl) {
    return '';
  }
  if (!firstName || !lastName) {
    return 'НП';
  }

  return firstName[0] + lastName[0];
}
